<template>
  <v-app app>
    <div class="">
      <v-divider></v-divider>
      <div class="title ml-4 my-8">УЗНАТЬ ШАНСЫ И ВЫИГРАТЬ ДЕЛО</div>
      <v-divider></v-divider>
      <h4 class="mt-6">Смотрите видеоинструкцию нашего юриста</h4>
      <p>
        Наш автоюрист-эксперт, специализирующийся на данной категории дел, провел закрытый вебинар на котором рассказал все хитрости и уловки автоюристов для возврата прав в самых сложных ситуациях. Эта закрытая запись доступна Вам. В открытых источниках и сотнях видео в YouTube такой информации нет Вам не больше не понадобится автоюрист, не нужны никакие бесплатные и платные консультации потому что ответы на все вопросы в этом интенсиве
      </p>
      <h4 class="mt-6">Вас ждут готовые документы и инструкции конкретно по вашей ситуации</h4>
      <p>
        Юристы и адвокаты работают по шаблонам, которые ими создаются на основе выигранных дел. Это самый эффективный способ - возвращать права используя постановления и решения судов всех инстанций вплоть до Верховного Суда РФ Мы учли все возможные ситуации!
      </p>
      <h4 class="mt-6">Подготовьте документы</h4>
      <p>
        После изучения видео Вы за 1 час по нашим шаблонам подготовите доказательную базу со своей стороны и документы, оспаривающие все доказательства ГИБДД. О том, что это нужно делать знают не многие, но еще меньше знают о том как это делать правильно чтобы все аргументы в суде были в Вашу пользу Ваш персональный автоюрист проверит подготовленные Вами документы
      </p>
      <h4 class="mt-6">Действуйте по инструкции</h4>
      <p>
        От нас Вы получаете четкую пошаговую инструкцию своих действий, которая выработана нами за годы возврата прав. Иногда даже не придется никуда ходить - достаточно будет отправить все подготовленные документы почтой.
      </p>
      <h4 class="mt-6">Ваше водительское удостоверение у Вас в кармане</h4>
      <p>
        Права возвращают только те, кто подготовился к защите. Кто совершает правильные последовательные действия. Судьи с уважением относятся к тем, кто подготовился к своей защите, представил реальные доказательства, а не глупые тексты из интернета
      </p>
      <br>
      <p>Ну как? Все просто и понятно! Достаточно просто получить доступ к записям и материалам.
        Сделать это можно через подписку, покупку разового доступа.
      </p>
      <h4 class="mb-4 mt-6">
        Оставьте заявку и мы все Вам подробно расскажем
      </h4>
    </div>
    <div style="background-color: #74AC48; padding: 40px 0" id="ya1">
      <div class="container pr-7 pr-lg-0 px-sm-8 pr-sm-11">
        <div style="font-size: 24px!important;color: white" class="title mb-5 mb-lg-8 mb-xl-12">
          <span style="color: #ffed00">Бесплатная консультация</span> по возврату прав
        </div>
        <v-form v-model="valid">
          <v-row>
            <v-col
              cols="12"
              md="3"
              class="pa-sm-0 px-md-1 pa-0 px-2"
            >
              <v-text-field
                v-model="firstName"
                :rules="nameRules"
                :counter="10"
                label="Имя"
                solo
                clearable
                required
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
              class="pa-sm-0 px-md-1 pa-0 px-2"
            >
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="11"
                label="Телефон"
                solo
                clearable
                required
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
              class="pa-sm-0 px-md-1 pa-0 px-2"
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                solo
                clearable
                required
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="pa-sm-0 px-md-1 pa-0 px-2"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#404040"
                    style="color: #FFED00"
                    height="56"
                    width="100%"
                    @click="valid?send():false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-if="statusSend">fas fa-circle-notch fa-spin</v-icon>
                    {{statusSend? '' : 'Получить'}}
                  </v-btn>
                </template>
                <span>{{!valid ? 'Заполните данные' : 'Отправить'}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-app>
</template>

<script>
import api from '../api/api'
export default {
  name: 'chance',
  data () {
    return {
      valid: false,
      statusSend: false,
      firstName: '',
      phone: '',
      nameRules: [
        v => !!v || 'Введите имя',
        v => (v && v.length <= 10) || 'Имя не должно быть длиннее 10 символов'
      ],
      phoneRules: [
        value => !!value || 'Обязательное поле',
        value => (Boolean(Number(value))) || 'Введите только цифры',
        value => (value && value.length >= 11) || 'Не менее 11 цифр',
        value => (value && value.length <= 11) || 'Не более 11 цифр'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail обязательное поле',
        v => /.+@.+/.test(v) || 'E-mail введен не правильно'
      ]
    }
  },
  methods: {
    async send () {
      if (this.valid) {
        this.statusSend = true
        const data = {
          name: this.firstName,
          phone: this.phone,
          email: this.email,
          website: 'auto.mfucentre.ru',
          generate: 'auto.mfucentre.ru',
          note: 'Заявка с auto.mfucentre.ru'
        }
        await api.importLead(data)
          .then(() => {
            this.dialog = true
            this.statusSend = false
          })
      } else return false
    }
  }
}
</script>

<style scoped lang="scss">
  h4 {
    color: rgba(0,0,0,.7);
    margin-bottom: 15px;
  }
  #ya1 {
    width: 100vw;
    transform: translateX(-50px)translateY(40px);
    @media (max-width: 450px) {
      transform: translateX(-20px)translateY(40px);
    }
  }
</style>
